<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--搜索框-->
      <div class="filterBarPay">
        <!--ID-->
        <div>
          <label>{{ $t('CompanyName') }}：</label>
          <input v-model.trim="donateId">
        </div>
        <!--捐款人-->
        <div>
          <label>{{ $t("PayPerson") }}：</label>
          <input
            type="text"
            v-model.trim="donateName"
          >
        </div>
        <!--捐款时间-->
        <div>
          <label>{{$t('PayTime')}}：</label>
          <el-date-picker
            v-model="createTime"
            type="date"
            :placeholder="$t('ChooseDate')"
            format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
        <span>{{$t('AllIncome') + donationTotal}}</span>
      </div>
      
      <!--表格-->
      <el-table
        :data="donationList"
        border
        style="width: 99%;margin: 0 auto"
      >
        <!--公司名-->
        <el-table-column
          prop="companyName"
          :label="$t('CompanyName')"
        >
        </el-table-column>
        <!--缴费人-->
        <el-table-column
          prop="donateName"
          :label="$t('PayPerson')"
        >
        </el-table-column>
        <!--捐款单位-->
<!--        <el-table-column
          prop="donateUnit"
          :label="$t('DonationUnit')"
        >
        </el-table-column>-->
        <!--缴费金额-->
        <el-table-column
          prop="donatePrice"
          :label="$t('PayAmount')"
        >
        </el-table-column>
        <!--捐款留言-->
<!--        <el-table-column
          prop="message"
          width="150"
          :label="$t('DonationNotes')"
        >
        </el-table-column>-->
        <!--缴费时间-->
        <el-table-column
          prop="createTime"
          :label="$t('PayTime')"
        >
        </el-table-column>
        <!--操作-->
<!--        <el-table-column
          prop="operation"
          width="210"
          :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="openNoteDialog(scope.row.message)"
              :disabled="!scope.row.message"
            >{{ $t("CheckNotes") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              style="color: red;"
            >{{ $t("Refund") }}
            </el-button>
          </template>
        </el-table-column>-->
      </el-table>
      
      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>
    
    <!--留言弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title='$t("CheckNotes")'
      :visible.sync="noteDialog"
      append-to-body
      center
      :show-close="false"
    >
      <el-form
        ref="createActivity"
        class="create-activity-dialog"
        v-if="noteDialog"
      >
        <div class="msgsDialog">
          <pre>{{messageDialog.note}}</pre>
          <!--<span>{{messageDialog.note}}</span>-->
        </div>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="noteDialog = false"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
//    import axios from 'axios'

import mixin from "@/assets/js/mixin";
import moment from "moment";

export default {
  mixins: [mixin],
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      myRight: data.userPermission,
      noteDialog: false,
      chamberId: data.chamberId,
      adminId: data.id,
      donationList: [],
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,
      isNormal: true,
      memberNote:'',
      donationTotal:0,
      
      // 搜索用
      donateId: "",
      donateName: "",
      donateUnit: "",
      createTime:"",
      
      messageText: "",
      
      initDonation: {
        page: 1,
        currentAdmin: data.id,
        tenantCode:JSON.parse(localStorage.getItem('cacheChamber')).adminData.tenantCode
      },
      
      messageDialog:{
        donationId:'',
        note:'',
      },
    };
  },
  mounted() {
    this.initUserData(1);
  },
  methods: {
    
    //            初始化用户列表
    initUserData(currentPage) {
      this.currentPage = currentPage;
      this.initDonation.page = currentPage;
      this.donateId
        ? (this.initDonation.companyName = this.donateId)
        : delete this.initDonation.companyName;
      this.donateName
        ? (this.initDonation.donateName = this.donateName)
        : delete this.initDonation.donateName;
      this.donateUnit
        ? (this.initDonation.donateUnit = this.donateUnit)
        : delete this.initDonation.donateUnit;
      this.createTime
        ? (this.initDonation.createTime = moment(this.createTime).format("YYYY-MM-DD"))
        : delete this.initDonation.createTime;
      this.initDonation.language = this.langToLanguage()
      api.getDonation(this.initDonation).then((res) => {
        this.donationList = res.data.donationList.pageList;
        this.userTotal = res.data.donationList.total;
        this.donationTotal = res.data.totalPrice
      });
    },
    
    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initUserData(currentPage);
    },
    
    //            -------------------------------------------------增删改查用户-----------------------------------------------
    
    //            搜索用户列表
    searchUserData() {
      // console.log("api调用", this.initDonation);
      this.initUserData(1);
    },
    
    //   跳转详情页
    openNoteDialog(memberNote){
      this.noteDialog = true
      this.messageDialog.note = memberNote
    },
    
  },
};
</script>
<style lang="scss" scoped>

.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filterBarPay {
  width: 99%;
  height: 75px;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  
  input {
    width: 200px;
    height: 30px;
    border: 1px solid white;
    margin: 8px 0;
    vertical-align: middle;
    float: left;
  }
  
  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    //line-height: 75px;
  }
  
  .el-button {
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677d95;
    color: white;
    
    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

pre{
  white-space: pre-wrap;
  word-wrap: break-word;
}

.create-activity-dialog{
  width: 95%;
  padding: 20px 30px;
  border: 1px solid #666666;
  min-height: 50px;
  margin: auto;
  
  .el-form-item{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    
    ::v-deep .el-form-item__label{
      min-width: 180px !important;
      float: left;
      text-align:center;
    }
    
    .el-input{
      width: 500px;
    }
    
    .el-textarea{
      width: 500px;
    }
  }
  
  .per-pay{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .el-select{
      width: 100px;
    }
    .pay-amount{
      width: 200px;
      margin: 0 20px;
      .el-input__inner{
        width: 200px!important;
      }
    }
  }
}
</style>

